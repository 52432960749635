<template>
  <div class="vue-form-generator">
    <b-card v-if="tagItem">
      <div slot="header">
        <strong>{{ $t('filtersSortItems') }}</strong>
      </div>
      <b-row
        style="margin-top: 15px; margin-bottom: 25px;"
        :class="{'error': $v.form.name.$invalid && $v.form.name.$dirty}"
      >
        <b-col
          sm="12"
          md="6"
          lg="3"
          offset-lg="4"
          offset-md="3"
        >
          <label>{{ $t('filterItemName') }}</label>
          <b-form-input v-model="$v.form.name.$model" min="0" required/>
          <div class="errors" v-if="$v.form.name.$invalid && $v.form.name.$dirty">
            <span v-if="!$v.form.name.required">{{ $t('nameRequired') }}.</span>
            <span v-if="!$v.form.name.maxLength">{{ $t('nameMaxLength') }}.</span>
            <span v-if="!$v.form.name.unique">{{ $t('nameAlreadyTaken') }}.</span>
          </div>
        </b-col>
        <b-col
          sm="12"
          md="6"
          lg="3"
          offset-lg="4"
          offset-md="3"
          style="margin-top: 5px;"
        >
          <b-button
            block
            variant="primary"
            :disabled="$v.form.name.$invalid && $v.form.name.$dirty"
            @click.prevent="addFilterItem"
          >
            {{ $t('addFilterItem') }}
          </b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          sm="12"
          md="6"
          lg="3"
          offset-lg="4"
          offset-md="3"
        >
          <draggable
            :list="tagItems"
            ghost-class="ghost"
            @start="dragging = true"
            @end="dragging = false"
          >
          <b-button
            block
            variant="outline-primary"
            style="margin-bottom: 4px;"
            v-for="relatedItem in tagItems"
            :disabled="!relatedItem.tagItem.status"
            :key="relatedItem.name"
          >
            {{ relatedItem.tagItem.brandName ? relatedItem.tagItem.brandName : relatedItem.tagItem.value }}
          </b-button>
          </draggable>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="12" md="6" lg="2">
          <b-button
            type="submit"
            block
            variant="primary"
            @click.prevent="submit"
          >{{ $t('save') }}</b-button>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
  import draggable from "vuedraggable";
  import {APIService} from "../../services/api";
  import i18n from "@/plugins/i18n";
  import {required, maxLength} from "vuelidate/lib/validators";

  export default {
    name: 'SortFilterTagItems',
    components: {
      draggable,
    },
    props: ['filterTagItemRelationId'],
    data: () => {
      return {
        tag: null,
        tagItem: null,
        dragging: false,
        tagItems: [],
        form: {
          name: '',
        },
      }
    },
    validations: {
      form: {
        name: {
          required,
          maxLength: maxLength(255),
          unique: (value) => {
            // return this.tagItems.filter((tagItem) => {
            //   return tagItem.tagItem.value === value || tagItem.tagItem.brandName === value;
            // }).length === 0;
            return true;
          },
        },
      }
    },
    methods: {

      async addFilterItem() {
        try {
          const name = this.$v.form.name.$model;
          const filterToken = this.tag.token;
          const existingFilterData = await APIService.get(`tag-item?join=tag&filter=value||$eq||${name}&filter=tag.token||$eq||${filterToken}`);
          let tagItem = null;
          if (existingFilterData.length > 0) {
            tagItem = existingFilterData[0];
          } else {
            tagItem = await APIService.post('tag-item', {
              value: name,
              shortDescription: name,
              description: name,
              status: 1,
              tag: this.tag,
            });
          }
          const maxOrderKey = this.tagItems.reduce((max, tagItem) => {
            return tagItem.orderKey > max ? tagItem.orderKey : max;
          }, 0);
          const filterItem = {
            orderKey: maxOrderKey + 1,
            tagItem,
          };
          this.tagItems.push(filterItem);
        } catch (e) {
          console.error(e);
          this.$eventBus.$emit('alert', {
            type: 'danger',
            text: i18n.t('errorOnDataSaving'),
          });
        }
        this.$v.form.name.$model = '';
        this.$v.form.name.$reset();
      },
      async submit() {
        const tagItems = this.tagItems.map((tagItemData, i) => {
          tagItemData.orderKey = i + 1;
          if (tagItemData.tagItem) {
            tagItemData.tagItem = {
              id: tagItemData.tagItem.id
            }
          }
          return tagItemData;
        })
        try {
          await APIService.patch(`filter-tag-item-relation/${this.filterTagItemRelationId}`, tagItems);
          this.$eventBus.$emit('alert', {
            type: 'success',
            text: i18n.t('dataWasSuccessfullySaved'),
          });
          this.$router.back();
        } catch (e) {
          console.error(e);
          this.$eventBus.$emit('alert', {
            type: 'danger',
            text: i18n.t('errorOnDataSaving'),
          });
        }
      },
    },
    async beforeMount() {
      try {
        const data = await APIService.get(`filter-tag-item-relation/${this.filterTagItemRelationId}`);
        const filter = data.filter;
        const tagData = await APIService.get(`tag?filter=token||$eq||${filter.token}`);
        if (tagData.length === 0) {
          throw new Error('Tag not found');
        }
        this.tag = tagData[0];
        this.tagItem = data.tagItem;
        this.tagItems = data.relatedTagItemsRelations;
      } catch (e) {
        console.error(e);
      }
    }
  }
</script>
